<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        HR Market
      </h2>
      <img
        src="../../assets/img/tools_hr_banner.jpg"
        alt="Legislation Banner"
        class="w-full lg:h-96 h-80 mt-4 object-cover"
      >
      <br>
      <br>
      <div>
        Understand the demand and supply of jobs in the
        market by monitoring the hiring advertisments in different locations.
      </div>
        <br>
        <img
          src="../../assets/img/tools_HRmarkets.png"
          alt="Expatriate Practice Banner"
          class="w-full mx-auto"
        >
        <br>
        <div>
          HR Market:
          <ul class="px-10 p-4 list-disc text-red-700">
            <li><b>Job posting</b> to monitor up or down trend,
            by job functions or hierarchies.</li>
            <li><b>HR Supply / Demand momentum</b> by country
            and industry</li>
          </ul>
        </div>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
